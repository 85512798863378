import React from 'react'

import red from '@material-ui/core/colors/red'
import NotInterested from '@material-ui/icons/NotInterested'

class NotFound extends React.Component {
	render() {
		return (
			<div className="shortstop-not-found" style={ { marginTop: 70 } }>
				<div style={ { marginTop: '15%', textAlign: 'center' } }>
					<NotInterested color={ red[700] } fontSize={ '100px' } />
					<h1 style={ { display: 'inline-block', fontWeight: 'bold' } }>Sorry, the page you&apos;re looking for doesn&apos;t exist.</h1>
				</div>
			</div>
		)
	}
}

export default ( NotFound )
